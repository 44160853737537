.problem_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1/3;
  margin-top: 4rem;
}

.problem_container > h6,
.problem_container > h3,
.problem_container > h4 {
  font-weight: 500;
  margin: 0;
  color: #383838;
}

.problem_container > h6 {
  font-size: 5vw;
}

.problem_container > h3 {
  font-size: 4.25vw;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-family: "Roboto";
  color: #5d5d5d;
}

.problem_container > span {
  text-align: center;
  font-weight: 100;
  color: #383838;
  font-size: 4.5vw;
  margin-bottom: 1.5rem;
}

.problem_container > h4 {
  font-size: 6vw;
  position: relative;
}

.problem_container > h4::after {
  content: "";
  top: 20%;
  left: -103%;
  width: 100%;
  height: 0.2rem;
  background-color: #383838;
  position: absolute;
}

.problem_container > h4 > span {
  position: relative;
  color: white;
}

.problem_container > h4 > span::after {
  content: "";
  top: -2.5%;
  left: -2.5%;
  position: absolute;
  z-index: -1;
  width: 105%;
  height: 105%;
  background-color: #4488ff;
  transform: rotate(-1deg);
  border-radius: 10%;
}
@media screen and (min-width: 601px) {
  .problem_container > h3 {
    font-size: 1.5vw;
    width: 40%;
  }

  .problem_container > span {
    margin: 2rem;
    font-size: 1.8vw;
  }

  .problem_container > h4 {
    font-size: 2.5vw;
    margin-top: 1.5rem;
  }

  .problem_container > h4 > span {
    position: relative;
    color: white;
  }

  .problem_container > h4 > span::after {
    content: "";
    top: -2.5%;
    left: -2.5%;
    position: absolute;
    z-index: -1;
    width: 105%;
    height: 105%;
    background-color: #4488ff;
    transform: rotate(-1deg);
    border-radius: 10%;
  }
}
