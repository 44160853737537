.main_title {
    font-size: 8vw;
    text-align: center;
    color: #383838;
    margin: 2rem 0 0 0;
    padding: 0;
    font-weight: 500;
}

.main_title > span {
    position: relative;
    color: white;
}

.main_title > span::after {
    content: '';
    top: -20%;
    left: -5%;
    width: 115%;
    height: 135%;
    background-color: #4488ff;
    z-index: -1;
    position: absolute;
    transform: rotate(10deg);
    border-radius: 400% 300% 150% 150% / 400% 200% 350% 100%;
}

.sub_title {
    font-size: 5vw;
    text-align: center;
    color: #383838;
    margin: 0.75rem 0 0 0;
    padding: 0;
    font-weight: 500;
}

.main_image {
    width: 60vw;
    margin: 1rem 0;
    align-self: center;
}

.sub_text {
    text-align: center;
    font-family: 'Roboto';
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 2rem;
}

@media screen and (min-width: 601px) {
    .main_title {
        font-size: 4vw;
        text-align: start;
        grid-column: 1;
        grid-row: 1;
        margin-top: 4rem;

    }
    .sub_title {
        font-size: 2vw;
        text-align: start;
        grid-column: 1;
        grid-row: 2;

    }
    .main_image {
        width: 35vw;
        grid-column: 2;
        grid-row: 1/5;
    }
    .sub_text {
        font-size: 1.5vw;
        grid-row: 3;
        text-align: start;
        margin-top: 3rem;
    }

}