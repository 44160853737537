.main__container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.signup_howItWorks {
  display: grid;
  grid-template-columns: 1fr;
}

.signup_howItWorks > .how_it_works_button {
  grid-row: 3;
  background: none;
  border: none;
  height: max-content;
  padding: 1rem 0;
  align-self: center;
  justify-self: center;
  font-size: 4.5vw;
  color: #383838;
  display: none;
}

@media screen and (min-width: 601px) {
  .main__container {
    padding: 0 10vw;
    display: grid;
    grid-template-columns: 1fr;
  }

  .signup_howItWorks {
    grid-template-columns: 2.25fr 1fr;
  }

  .signup_howItWorks > .how_it_works_button {
    font-size: 1.2vw;
    grid-column: 2;
    grid-row: 1;
    margin-top: 0;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    display: block;
  }

  .signup_howItWorks > .how_it_works_button::after {
    content: "";
    position: absolute;
    top: calc(100% - 1rem);
    left: -100%;
    width: 100%;
    height: 0.05rem;
    background-color: #383838;
    transition: left 0.5s ease-in-out;
  }

  .signup_howItWorks > .how_it_works_button:hover::after {
    left: 0%;
  }

  @keyframes hoverUnderline {
    100% {
      left: 0;
    }
  }

  .signup_howItWorks > .how_it_works_button:hover {
    cursor: pointer;
  }
}
