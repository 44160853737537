nav {
  border-bottom: 1px solid black;
  padding: 0 5rem;
}
nav > img {
  width: 10rem;
}

@media screen and (max-width: 600px) {
  nav {
    display: flex;
    justify-content: center;
    padding: 0.5rem 5rem;
  }
  nav > img {
    width: 40vw;
  }
}
