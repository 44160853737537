form {
  display: flex;
  flex-direction: column;
}

form > input {
  padding: 3.5vw;
  font-size: 4vw;
  background-color: #a5c5fe;
  margin: 0;
  border-radius: 0;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border: none;
  font-weight: 600;
  font-family: "Roboto";
}

form > input::placeholder {
  color: white;
  font-weight: 600;
  font-family: "Roboto";
}

form > button {
  border: none;
  background-color: #4488ff;
  margin: 0;
  color: white;
  font-size: 4.5vw;
  padding: 3vw;
  font-family: "Roboto";
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  animation: glow 1s ease-in-out infinite;
  font-weight: 500;
}

form > button:disabled {
  background-color: gray;
}

form > .email_error,
form > .email_success {
  font-size: 4vw;
  text-align: center;
  margin-bottom: 0.25rem;
  color: rgb(218, 45, 45);
  font-weight: 100;
}

form > .email_success {
  color: rgb(97, 202, 97);
}

@keyframes glow {
  0% {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 8px;
  }
  50% {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 3px;
  }
  100% {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 8px;
  }
}

.email_subtext {
  margin-top: 1rem;
  text-align: center;
  font-family: "Roboto";
  color: gray;
  font-size: 3.75vw;
  font-weight: 500;
}

@media screen and (min-width: 601px) {
  form > input {
    padding: 1vw;
    font-size: 1.2vw;
    grid-column: 1;
  }
  form > button {
    padding: 1vw;
    font-size: 1.2vw;
  }
  .email_subtext {
    font-size: 1vw;
    margin-bottom: 2rem;
    grid-column: 1;
    grid-row: 2;
  }
  form > .email_error,
  form > .email_success {
    font-size: 1vw;
  }
}
